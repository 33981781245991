import zlRequest from '../../../index'

import type { AdminType } from './types'

import { reqDataType } from '../../../types'

enum adminAPI {
  adminList = '/user/admin/list',
  addAdmin = '/user/admin'
}

export async function getAdminList() {
  return zlRequest.get<reqDataType<AdminType[]>>({
    url: adminAPI.adminList
  })
}

export async function addAdmin(info: { name: string; password: string }) {
  return zlRequest.post<reqDataType>({
    url: adminAPI.addAdmin,
    data: {
      ...info
    }
  })
}

export async function changeAdmin(info: {
  id: number
  name: string
  password: string
  auth: number
}) {
  return zlRequest.patch<reqDataType>({
    url: adminAPI.addAdmin,
    data: info
  })
}

export async function deleteAdmin(id: number) {
  return zlRequest.delete<reqDataType>({
    url: '/user/' + id + '/admin'
  })
}
