import { ITableLabel } from '@/base-ui/table/types'
import { AdminProps } from '../types'

const adminTableConfig: ITableLabel<AdminProps>[] = [
  {
    type: 'id',
    label: '排序',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '用户名',
    prop: 'name',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    tag: {
      type: 'success'
    },
    label: '权限',
    prop: 'auth',
    options: {
      align: 'center'
    },
    ifPropFields: {
      ifProp: '1',
      value1: {
        text: '超级管理员',
        tagOptions: {
          type: 'success',
          effect: 'dark',
          size: 'large'
        }
      },
      value2: {
        text: '管理员',
        tagOptions: {
          type: '',
          effect: 'dark',
          size: 'large'
        }
      }
    }
  },
  {
    type: 'normal',
    label: '创建时间',
    prop: 'createAt',
    options: {
      align: 'center'
    }
  },
  {
    type: 'operations',
    options: {
      align: 'right'
    },
    headerItem: {
      type: 'header',
      placeholder: '用户名搜索......',
      options: {
        size: 'small'
      }
    },
    operationsOption: [
      {
        value: '修改',
        btnAttribute: {
          size: 'small',
          type: 'primary'
        }
      },
      {
        value: '删除',
        popconfirm: {
          title: '确认删除该管理员？',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        },
        btnAttribute: {
          size: 'small',
          type: 'danger'
        }
      }
    ]
  }
]

export { adminTableConfig }
